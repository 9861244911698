<template>
	<div class="basket" ref="strollTo" v-if="!loading">
		<v-form
			ref="form"
			lazy-validation

		>
			<v-row dense>
				<v-col cols="12" md="8">
					<v-row dense>

						<block-billing-details class="billingDetails"/>

						<block-address class="address"/>

						<block-contact class="contact"/>

						<block-additional-information class="additionalInformation"/>

						<block-products class="products"/>

						<block-terms class="terms"/>

					</v-row>
				</v-col>
				<v-col cols="12" md="4" class="">
					<div class="basket--order-summary">
						<v-card class="pa-4" outlined>
							<div class="text-h5 mb-4">Order summary</div>
							<div class="d-flex">
								<div>Retail value of your order:</div>
								<v-spacer/>
								<div>{{ retailPrice / 100 | euro }}</div>
							</div>
							<div class="d-flex">
								<div>Your discount level:</div>
								<v-spacer/>
								<div>{{ activeDl }}</div>
							</div>
							<div class="d-flex text-h6 align-center">
								<div>Wholesale value of your order:</div>
								<v-spacer/>
								<div class="text-no-wrap font-weight-700">{{ clientPrice / 100 | euro }}<span
									class="red--text">*</span></div>
							</div>

							<div class="mt-0 text-body-2 pa-2 red--text font-weight-400 text-justify">
								<div class="mb-2">
									*
									The value of the order does not include any additional fees that may apply.
								</div>
								<div class="mb-2">
									*
									The cost of transport will be added to the wholesale value of your order.
								</div>
								<div class="mb-2">
									*
									After placing the order, it has to be verified by the administrator.
								</div>
							</div>

							<v-divider class="my-2"/>

							<v-btn
								class="mt-6"
								color="error"
								block
								depressed
								@click="orderNow"
								:loading="loadingBtnOrderNow"
								:disabled="disabled"
								id="basket-order_now"
							>
								CONFIRM & ORDER
							</v-btn>
						</v-card>

					</div>
				</v-col>
			</v-row>

		</v-form>
	</div>
</template>
<script>


import BlockProducts from "@/views/Basket/Block/BlockProducts";
import BlockAddress from "@/views/Basket/Block/BlockAddress";
import BlockContact from "@/views/Basket/Block/BlockContact";
import BlockTerms from "@/views/Basket/Block/BlockTerms";
import BlockAdditionalInformation from "@/views/Basket/Block/BlockAdditionalInformation";
import BlockBillingDetails from "@/views/Basket/Block/BlockBillingDetails";

export default {
	name: 'BasketIndex',
	components: {
		BlockBillingDetails,
		BlockAdditionalInformation,
		BlockTerms,
		BlockContact,
		BlockAddress,
		BlockProducts
	},
	computed: {
		activeDl() {
			return this.$store.getters['BASKET_STORE/ACTIVE_DL'];
		},
		retailPrice() {
			return this.$store.getters['BASKET_STORE/RETAIL_PRICE'];
		},
		clientPrice() {
			return this.$store.getters['BASKET_STORE/CLIENT_PRICE'];
		},
		disabled() {
			return this.$store.getters['BASKET_STORE/PRODUCTS'].length < 1;
		},
	},
	data: () => ({
		loading: false,

		loadingBtnOrderNow: false,
	}),
	methods: {
		routeTo() {
			const el = this.$refs.strollTo;
			if (el) {
				const el = this.$el.getElementsByClassName('terms')[0];
				if (el) {
					el.scrollIntoView({behavior: 'smooth'});
				}
			}
		},

		orderNow() {
			this.$store.commit('BASKET_STORE/VALID_FORM_SET', true);
			if (!this.$refs.form.validate()) {
				this.routeTo();
				return;
			}

			this.$gtag.event(
				'Custom',
				{
					'klik': 'basket-confirm_order',
				}
			);


			this.loadingBtnOrderNow = true;
			this.$store.dispatch('BASKET_STORE/orderNow')
				.then((resp) => {
					this.loadingBtnOrderNow = false;
					if (resp.data.success) {
						const order = resp.data.data;

						this.$gtag.event(
							'purchase',
							{
								transaction_id: 'B2B-ID-' + order.id,
								value: (order.total_payment_client / 100).toFixed(2),
								tax: 0,
								shipping: 0,
								currency: "EUR",
								items: order.products
									.filter(item => item.type === 'product')
									.map((item, index) => {
										return {
											index: index,
											item_id: item.id,
											item_name: item.name,
											discount: 0,
											item_brand: "WOOKAH",
											price: (item.client_price / 100).toFixed(2),
											quantity: item.quantity,
											item_category: item.group ? item.group.name : '---',
										}
									}),
							}
						);

						this.$router.push({name: 'ConfirmationIndex'})
					}
				})
				.catch(err => {
					console.error(err)
					this.loadingBtnOrderNow = false
				});
		},
	},
	created() {
		this.loading = true;

		this.$store.dispatch('BASKET_STORE/getBasket')
			.then(() => {
				this.loading = false
				this.$nextTick(() => this.$refs.form.reset())
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	}
};
</script>
