<template>
	<v-col cols="12">

		<v-card class="pa-4" outlined>
			<div class="text-h5 mb-4">Regulations</div>

			<v-list dense v-if="!loading">
				<v-list-item
					v-for="(item, index) in approvals"
					:key="index"
				>
					<v-list-item-action>
						<v-checkbox
							class=""
							label=""
							:value="false"
							:false-value="false"
							:true-value="true"
							v-model="checkbox[item.slug]"
							hide-details="auto"
							:rules="[v => !!v || 'Before proceeding, please accept the required regulations!']"
						/>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title
							class="text-body-1 font-weight-400 black--text text-wrap"
							v-html="item.name"
						/>
						<small class="red--text mt-1" v-if="validForm && !checkbox[item.slug]">
							Before proceeding, please accept the required regulations!
						</small>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>

	</v-col>
</template>
<script>

export default {
	name: 'BlockTerms',
	components: {},
	computed: {
		validForm() {
			return this.$store.getters['BASKET_STORE/VALID_FORM'];
		},

	},
	data: () => ({
		loading: true,
		approvals: [],
		checkbox: {},
	}),
	methods: {
		check() {
			this.valid = true;
			if (!this.$refs.form.validate()) {
				return;
			}
		},
	},
	watch: {},
	created() {
		this.$store.dispatch('SETTINGS_APPROVALS/index')
			.then(resp => {
					let approvals = resp.data.data.filter(i => i.required);

					for (let i = 0; i < approvals.length; i++) {
						let name = approvals[i].name;
						let linkAndUrl = name.split('[[')[1];
						if (linkAndUrl) {
							let route = linkAndUrl.split(']]')[0];
							if (route) {
								let [name, routerName] = route.split('|');
								let href = '#';
								if (routerName) {
									let routeObject = this.$router.getRoutes().find(i => i.name === routerName);
									if (routeObject) {
										href = routeObject.path;
									}
								}
								let a = '<a class="blue--text" href="' + href + '" target="_blank">' + name + '</a>';
								approvals[i].name = approvals[i].name.replace('[[' + route + ']]', a)
							}
						}
					}

					this.approvals = approvals;

					this.loading = false
				}
			)
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	}
};
</script>
