<template>
	<div class="tiptap">
		<div v-if="editor" class="tiptap--toolbar">
			<v-btn
				@click="editor.chain().focus().toggleBold().run()"
				:class="{ 'active': editor.isActive('bold') }"
				icon
			>
				<remix-icon name="bold"/>
			</v-btn>
			<v-btn
				@click="editor.chain().focus().toggleItalic().run()"
				:class="{ 'active': editor.isActive('italic') }"
				icon
			>
				<remix-icon name="italic"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleUnderline().run()"
				:class="{ 'active': editor.isActive('underline') }"
				icon
			>
				<remix-icon name="underline"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleStrike().run()"
				:class="{ 'active': editor.isActive('strike') }"
				icon
			>
				<remix-icon name="strikethrough"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleCode().run()"
				:class="{ 'active': editor.isActive('code') }"
				icon
			>
				<remix-icon name="code-view"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleHighlight().run()"
				:class="{ 'active': editor.isActive('highlight') }"
				icon
			>
				<remix-icon name="mark-pen-line"/>
			</v-btn>
			<v-btn
				@click="editor.chain().focus().unsetAllMarks().clearNodes().run()"
				icon
			>
				<remix-icon name="format-clear"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().setTextAlign('left').run()"
				:class="{ 'active': editor.isActive({ textAlign: 'left' }) }"
				icon
			>
				<remix-icon name="align-left"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().setTextAlign('center').run()"
				:class="{ 'active': editor.isActive({ textAlign: 'center' }) }"
				icon
			>
				<remix-icon name="align-center"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().setTextAlign('right').run()"
				:class="{ 'active': editor.isActive({ textAlign: 'right' }) }"
				icon
			>
				<remix-icon name="align-right"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().setTextAlign('justify').run()"
				:class="{ 'active': editor.isActive({ textAlign: 'justify' }) }"
				icon
			>
				<remix-icon name="align-justify"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
				:class="{ 'active': editor.isActive('heading', { level: 1 }) }"
				icon
			>
				<remix-icon name="h-1"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
				:class="{ 'active': editor.isActive('heading', { level: 2 }) }"
				icon
			>
				<remix-icon name="h-2"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
				:class="{ 'active': editor.isActive('heading', { level: 3 }) }"
				icon
			>
				<remix-icon name="h-3"/>
			</v-btn>

			<v-btn
				@click="editor.chain().focus().toggleBulletList().run()"
				:class="{ 'active': editor.isActive('bulletList') }"
				icon
			>
				<remix-icon name="list-unordered"/>
			</v-btn>
			<v-btn
				@click="editor.chain().focus().toggleOrderedList().run()"
				:class="{ 'active': editor.isActive('orderedList') }"
				icon
			>
				<remix-icon name="list-ordered"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleCodeBlock().run()"
				:class="{ 'active': editor.isActive('codeBlock') }"
				icon
			>
				<remix-icon name="code-box-line"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().toggleBlockquote().run()"
				:class="{ 'active': editor.isActive('blockquote') }"
				icon
			>
				<remix-icon name="double-quotes-l"/>
			</v-btn>
			<v-btn
				v-if="!smallEditor"
				@click="editor.chain().focus().setHorizontalRule().run()"
				icon
			>
				<remix-icon name="separator"/>
			</v-btn>
			<v-btn
				@click="editor.chain().focus().setHardBreak().run()"
				icon
			>
				<remix-icon name="text-wrap"/>
			</v-btn>
			<v-btn
				@click="editor.chain().focus().undo().run()"
				icon
			>
				<remix-icon name="arrow-go-back-line"/>
			</v-btn>
			<v-btn
				@click="editor.chain().focus().redo().run()"
				icon
			>
				<remix-icon name="arrow-go-forward-line"/>
			</v-btn>
		</div>

		<editor-content :editor="editor" :class="classes" class="overflow-y-auto editor-mh-500"/>

		<div class="character-count grey--text" v-if="1===3">
			<v-divider/>
			<div class="pt-2 text-right">{{ editor.getCharacterCount() }} znaków</div>
		</div>
	</div>
</template>

<script>
import {
	Editor,
	EditorContent,
} from '@tiptap/vue-starter-kit'
import StarterKit from '@tiptap/starter-kit?min'
import Highlight from '@tiptap/extension-highlight';
import Typography from '@tiptap/extension-typography';
import CharacterCount from '@tiptap/extension-character-count'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import Gapcursor from '@tiptap/extension-gapcursor'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'


import RemixIcon from "@/components/items/RemixIcon";

export default {
	components: {
		RemixIcon,
		EditorContent,
	},
	props: {
		content: {
			type: String,
			required: false,
			default: '',
		},
		big: {
			type: Boolean,
			required: false,
			default: false,
		},
		smallEditor: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	computed: {
		classes() {
			return this.big ? 'editor-h-200' : '';
		},
	},
	data() {
		return {
			editor: null,
			limit: 0,
		}
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
				Highlight.configure({multicolor: true}),
				Typography,
				CharacterCount.configure({
					limit: this.limit,
				}),
				Image,
				Dropcursor,
				Gapcursor,
				TextAlign,
				Underline,
			],
			onCreate: () => {
				if (this.editor) {
					this.$emit('text', {
						json: this.editor.getJSON(),
						html: this.editor.getHTML(),
					})
				}
			},
			onUpdate: () => {
				this.$emit('text', {
					json: this.editor.getJSON(),
					html: this.editor.getHTML(),
				})
			},
			content: '',
		});
		this.editor.commands.setContent(this.content);
	},
	beforeDestroy() {
		this.editor.destroy()
	},
}
</script>
<style>
.editor-h-200 > .ProseMirror {
	min-height: 200px;
}
.editor-mh-500 > .ProseMirror {
	max-height: 500px;
}
</style>