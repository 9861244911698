<template>
	<v-icon :class="classIcon" :style="styleIcon"/>
</template>

<script>
export default {
	name: 'RemixIcon',
	props: {
		name: {
			type: String,
			required: true,
		},
		size: {
			type: Number,
			required: false,
			default: 16
		},
	},
	computed: {
		classIcon() {
			return 'ri-' + this.name;
		},
		styleIcon() {
			return 'font-size: ' + this.size + 'px;';
		},
	},
};
</script>