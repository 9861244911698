<template>
	<v-col cols="12">
		<v-card class="pa-4" outlined v-if="client">

			<div class="text-h5 mb-4">Billing details</div>
			<div class="mb-2">
				<span class="black--text">{{ client.name }}</span><br>
				<span class="black--text">{{ client.billing_detail.street }}</span><br>
				<span class="black--text mr-1">{{ client.billing_detail.postal_code }}</span>
				<span class="black--text">{{ client.billing_detail.city }}</span><br>
				<span class="black--text">{{ client.billing_detail.country.name }}</span>
				<span v-if="client.billing_detail.state" class="black--text">,
					{{ client.billing_detail.state.name }}
				</span>
				<br>
				<span v-if="client.billing_detail.vat_ue" class="black--text">
					VAT UE: {{ client.billing_detail.vat_ue }}
				</span>
				<span v-if="client.billing_detail.vat_uk" class="black--text">
					VAT UK: {{ client.billing_detail.vat_uk }}
				</span>
				<span v-if="client.billing_detail.company_data" class="black--text">
					Company data: {{ client.billing_detail.company_data }}
				</span>
				<br>
			</div>

		</v-card>
	</v-col>

</template>
<script>

export default {
	name: 'BlockBillingDetails',
	components: {},
	computed: {
		client() {
			return this.$store.getters['BASKET_STORE/CLIENT'];
		},
	},
	data: () => ({
	}),
	methods: {
	},
	created() {
	}
};
</script>
