<template>
	<v-col cols="12">
		<v-card class="pa-4" outlined>
			<div class="text-h5 mb-4">Contact details</div>
			<div class="mb-2" v-if="address">
				<span class="black--text">{{ address.name }}</span><br>
				<span class="black--text">Email: {{ address.email }}</span><br>
				<span class="black--text mr-1">Phone: {{ address.phone }}</span>
				<br>
			</div>
			<v-alert v-else type="error">
				Select a contact detail!
			</v-alert>

			<v-dialog v-model="dialog" max-width="600px">
				<template v-slot:activator="{ on }">
					<v-btn
						color="blue"
						text
						class="white--text mt-2"
						v-on="on"
						@click="dialogChangeAddress"
					>
						Change contact detail
					</v-btn>
				</template>
				<v-card>
					<v-card-title class="text-h5 text-md-h4 font-weight-400 mb-2 d-flex align-center">
						<div>Saved contact details</div>
						<v-spacer/>
						<div class="" @click="dialog = false">
							<v-btn
								icon
								text
							>
								<v-icon size="18">mdi-close</v-icon>
							</v-btn>
						</div>
					</v-card-title>
					<v-card-text>
						<v-item-group v-model="newAddressIndex" mandatory>
							<v-row>
								<v-col
									v-for="(address, index) in items"
									:key="index"
									cols="12"
								>
									<v-item v-slot="{ active }">
										<div
											@click="changeAddress(address)"
											class="border-1s w-100 px-2 py-4 cursor--pointer"
											:class="{'border--primary border-2s':active}"
										>
											<span class="black--text">{{ address.name }}</span><br>
											<span class="black--text">Email: {{ address.email }}</span><br>
											<span class="black--text mr-1">Phone: {{ address.phone }}</span>
											<br>
										</div>
									</v-item>
								</v-col>
							</v-row>
						</v-item-group>
					</v-card-text>
					<v-card-actions class="">
						<v-btn
							color="primary"
							text
							block
							class="white--text mb-2"
							@click="moveToProfile"
						>
							Edit/add contact details
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

		</v-card>
	</v-col>

</template>
<script>

export default {
	name: 'BlockContact',
	components: {},
	computed: {
		items() {
			return this.$store.getters['BASKET_STORE/CONTACT_DETAIL'];
		},
		selected() {
			return this.$store.getters['BASKET_STORE/DATA'].contactDetail;
		},
		address() {
			return this.items.find(item => item.id === this.selected) ?? null;
		},
	},
	data: () => ({
		dialog: false,
		newAddressIndex: null,
	}),
	methods: {
		dialogChangeAddress() {
			this.newAddressIndex = this.items.findIndex(i => i.id === this.selected);
		},
		changeAddress(address) {
			this.$store.commit('BASKET_STORE/DATA_SET', {
				name: 'contactDetail',
				value: address.id
			});
			this.dialog = false;
		},
		moveToProfile() {
			this.$store.commit('PROFILE_CLIENT_STORE/MOVE_TO_SET', 'contact');

			this.$router.push({name: 'ProfileIndex'});
		},
	},
	created() {
	}
};
</script>
