<template>
	<v-col cols="12">
		<v-card class="pa-4" outlined>
			<div class="text-h5 mb-4">Additional information</div>

			<div class="border-1s pa-2">
				<tip-tap
					:big="true"
					:sma="true"
					v-on:text="getText"
				/>
			</div>
		</v-card>

	</v-col>
</template>
<script>

import TipTap from "@/components/TextEditor/TipTap";

export default {
	name: 'BlockAdditionalInformation',
	components: {TipTap},
	computed: {
		additionalInformation: {
			get() {
				return this.$store.getters['BASKET_STORE/ADDITIONAL_INFORMATION'];
			},
			set(value) {
				this.$store.commit('BASKET_STORE/ADDITIONAL_INFORMATION_SET', value);
			}
		},
	},
	data: () => ({}),
	methods: {
		getText(value) {
			if (value.html === '<p></p>'){
				this.additionalInformation = null;
			}else{
				this.additionalInformation = value.html;
			}
		},
	},
	created() {
	}
};
</script>
